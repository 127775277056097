<template>
  <VTable
    :requestIsAuth="requestIsAuth"
    :head-row="headArray"
    :url-set-rows="urlSetRows"
    :url-action-query="getUrlActionQuery"
    :custom-list-action="customListAction"
    :transformObject="transformObject"
    :is-checkbox="isCheckbox"
    :touch-bar-list="touchBar"
    @link="goLink"
  />
</template>

<script setup>
import { computed, defineProps } from 'vue'
import VTable from '@/components/ui/table/VTable'
import { lotTouchBarList } from '@/utils/touchbar'
import { deleteLot } from '@/http/lot/lotApi'
import { transformLotObject, getMemberLot } from '@/utils/utils'
import { useRouter } from 'vue-router'
import { LotController } from '@/controllers/LotController'

const router = useRouter()
const lotController = new LotController()

const props = defineProps({
  touchBar: {
    type: Array,
    required: false,
    default: lotTouchBarList
  },
  isCheckbox: {
    type: Boolean,
    required: false,
    default: true
  },
  requestIsAuth: {
    type: Boolean,
    required: false
  },
  urlSetRows: {
    type: String,
    required: false
  },
  urlActionQuery: {
    type: String,
    required: false
  },
  isChosenIcon: {
    type: Boolean,
    required: false,
    default: false
  },
})

const getUrlActionQuery = computed(() => {
  return props.urlActionQuery
})

const headArray = [
  {
    name: '№Лота:',
    value: 'id',
    width: '60px'
  },
  {
    // ?
    name: 'Наименование',
    value: 'name',
    width: '150px',
    decorator: {
      name: 'Link'
    }
  },
  {
    name: 'Статус',
    value: 'status',
    width: '100px'
  },
  {
    // ?
    name: 'Кол-во участников',
    value: 'member',
    width: '100px'
  },
  {
    name: 'Дата и  время публикации',
    value: 'published_at',
    width: '120px'
  },
  {
    name: 'Время до старта',
    value: 'timeToStart',
    width: '110px'
  },
  {
    name: 'Дата и время начало ТП',
    value: 'started_at',
    width: '110px'
  },
  {
    name: 'Дата и время окончания ТП',
    value: 'duration',
    width: '120px'
  }
]

if (props.isChosenIcon) {
  headArray.push({
    name: 'Избранное',
    width: '150px',
    decorator: {
      name: 'lotFavorites'
    }
  })
}

const transformObject = async (data) => {
  return transformLotObject(data, true)
}

const customListAction = new Map([
  ['delete', deleteLot],
  ['deleteBeforePublic', deleteBeforePublic],
  ['cancel', cancel],
  ['hide', hide],
  ['public', fnPublic],
  ['open', open],
  ['extend', extend],
  ['restore', restore]
])

async function deleteBeforePublic(id) {
  return await lotController.deleteBeforePublic(id)
}

async function cancel(id) {
  return await lotController.cancel(id)
}

async function hide(id) {
  return await lotController.hide(id)
}

async function fnPublic(id) {
  return await lotController.public(id)
}

async function open(id) {
  return await lotController.open(id)
}

async function extend(id, data = {}) {
  return await lotController.extend(id, data)
}

async function restore(id) {
  return await lotController.restore(id)
}

const goLink = (val) => {
  if (val.item.sent) {
    router.push({ name: 'organizer-create-trade', params: { id: val.item.id }, query: { integration: 'integration' } })
    return
  }
  const member = getMemberLot()
  router.push({ name: `${member}-lot-info`, params: { id: val.item.id } })
}

</script>
