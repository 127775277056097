<template>
  <div class="my-trade">
    <VTrade
      :requestIsAuth="requestIsAuth"
      v-if="getDisplay"
      :url-set-rows="urlSetRows ? urlSetRows : TRADE_OFFER_URL"
      :url-action-query="getUrlActionQuery ? getUrlActionQuery : EXPAND_LOT"
      :touch-bar="touchBar"
      :is-checkbox="isCheckbox"
      :is-chosen-icon="isChosenIcon"
    />
    <VLotList
      v-else
      :request-is-auth="requestIsAuth"
      :url-set-rows="urlSetRows ? urlSetRows : TRADE_OFFER_URL"
      :is-chosen-icon="isChosenIcon"
      @lotClick="click"
      :url-action-query="getUrlActionQuery ? getUrlActionQuery : EXPAND_LOT"
    />
  </div>
</template>

<script>
import VLotList from '@/components/list/LotList/VLotList'
import VTrade from '@/components/views/Trade/VTrade'
import { computed } from 'vue'
import { goLotInfo } from '@/utils/goRoute'
import { TRADE_OFFER_URL, EXPAND_LOT } from '@/utils/consts'
import { useRouter } from 'vue-router'
import { getMemberLot } from '@/utils/utils'
import { useStore } from 'vuex'

export default {
  props: {
    isChosenIcon: {
      type: Boolean,
      required: false
    },
    urlSetRows: {
      type: String,
      required: false
    },
    urlActionQuery: {
      type: String,
      required: false
    },
    requestIsAuth: {
      type: Boolean,
      required: false,
      default: true
    },
    isCheckbox: {
      type: Boolean,
      required: false,
      default: true
    },
    touchBar: {
      type: Array,
      required: false
    }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()

    const getUrlActionQuery = computed(() => {
      return props.urlActionQuery
    })

    const getDisplay = computed(() => {
      return store.getters['display/getIsDisplayTable']
    })

    const click = (val) => {
      const member = getMemberLot()
        router.push({
          name: `${member}-lot-info`,
          params: {
            id: val
          }
        })
    }

    return {
      getDisplay,
      click,
      goLotInfo,
      TRADE_OFFER_URL,
      EXPAND_LOT,
      getUrlActionQuery
    }
  },
  components: {
    VTrade,
    VLotList
  }
}
</script>
